import Layout from 'components/Layout';
import Nav from 'components/Nav';
import { above } from 'components/config/mediaqueries';
import { Link } from 'gatsby';
import styled from 'libs/styled';
import React from 'react';

const P = styled('p')``;

const Wrapper = styled('div')`
    display: grid;
    grid-template-columns: var(--grid-template);
    width: 100%;
    font-family: 'Roboto';
    line-height: 1.3;

    ${above.tabletSm} {
        grid-gap: 12px;
    }

    ${above.desktopSm} {
        grid-gap: 12px;
    }

    ${above.desktopXl} {
        max-width: 1920px;
        margin: 42px auto;
    }
`;

const TextWrapper = styled('div')`
    line-height: 1.5;
    grid-column: 2 / span 22;
    grid-row: 2;
    margin-top: 56px;

    ${above.tabletSm} {
        margin-top: 24px;
        grid-column: 4 / span 10;
    }
    ${above.desktopLg} {
        margin-top: 24px;
        grid-column: 6 / span 8;
        grid-row: 2;
    }

    p {
        margin: 0;
        margin-bottom: 12px;
    }
`;

const Heading = styled('h1')`
    line-height: 1;
    grid-column: 2 / span 22;
    font-size: 11.9vw;
    margin-left: -0.075em;

    ${above.tabletSm} {
        grid-column: 4 / span 20;
        font-size: 10vw;
    }
    ${above.desktopLg} {
        grid-column: 6 / span 15;
        font-size: 7.6vw;
    }

    ${above.desktopXl} {
        font-size: 130px;
    }
`;

const NotFoundPage = () => (
    <Layout>
        <Wrapper mt={['16px', '24px', '48px']}>
            <Nav />
            <Heading>Can't find that sry</Heading>
            <P
                fontFamily="Kalam"
                fontSize={['16px', null, '18px']}
                gridColumn={['2 / span 22', '4 / span 20', null, null, null, '6 / span 15']}
                gridRow="2"
                marginTop={['36px', 'unset']}
            >
                (404 - not found)
            </P>
            <TextWrapper fontSize={['16px', null, '18px']} mt="24px">
                <Link to="/">Go to homepage</Link>
            </TextWrapper>
        </Wrapper>
    </Layout>
);

export default NotFoundPage;
